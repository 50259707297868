import Navbar from "../../../Components/Layouts/Navbar";
import React, { useState, useEffect, useRef } from "react";
import { MdArrowOutward } from "react-icons/md";
import SectionHeader from "../../../Components/Common/SectionHeader";
import banner1 from "../../../assets/banner1.png";
import banner2 from "../../../assets/banner2.png";
import banner3 from "../../../assets/banner3.png";
import shape1 from "../../../assets/shape.png";
import Slider from "react-slick";
import SidebarNav from "../../../Components/Common/SidebarNav";
import { motion } from "framer-motion";

const HeroSection = () => {
  const [visibleRight, setVisibleRight] = useState(false);
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [headerBrand, setHeaderBrand] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [headerButton, setHeaderButton] = useState("");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlideIndex) => {
      setCurrentSlide(currentSlideIndex);
    },
  };

  const dummyData = [
    { id: 1, img: banner1 },
    { id: 2, img: banner2 },
    { id: 3, img: banner3 },
  ];
  useEffect(() => {
    if (currentSlide === 0) {
      setHeaderBrand(
        <>
          <p>Connecting</p>
          <span className="header-brand md1:text-[60px] md1:leading-[72px] sm1:text-[68px]  md:text-[71px] lg:text-[71px] sm:text-[55px] sm:leading-[67px] xs:text-[37px] xl:text-[80px] text-[80px] xs:leading-[48px] xl:leading-[86px] text-[#2244FF] relative">
            Brands
            <img src={shape1} alt="" className="absolute bottom-0 left-0" />
          </span>{" "}
          With The World
        </>
      );
      setHeaderText(
        "You have an idea, we have the talent and resources to turn it into a reality. Digital Gravity is a globally recognized digital agency elevating brands."
      );
      setHeaderButton("Let’s Explore");
    } else if (currentSlide === 1) {
      setHeaderBrand(
        <>
          <p>Transforming</p>
          <span className="header-brand md1:text-[60px] md1:leading-[72px] md:text-[71px] lg:text-[71px] sm:text-[67px] xs:text-[37px] xl:text-[80px] text-[80px] xs:leading-[58px] xl:leading-[86px] text-[#2244FF] relative">
            Innovative
            <img
              src={shape1}
              alt=""
              className="absolute bottom-[-10px] left-0 w-[500px]"
            />
          </span>{" "}
          solutions
        </>
      );
      setHeaderText(
        "You have an idea, we have the talent and resources to turn it into a reality. Digital Gravity is a globally recognized digital agency elevating brands."
      );
      setHeaderButton("Let’s Explore");
    } else if (currentSlide === 2) {
      setHeaderBrand(
        <>
          <p>Ecommerce</p>
          <span className="header-brand md1:text-[60px] md1:leading-[72px] md:text-[71px] lg:text-[71px] sm:text-[67px] xs:text-[37px] xl:text-[80px] text-[80px] xs:leading-[58px] xl:leading-[86px] text-[#2244FF] relative">
            Solutions
            <img
              src={shape1}
              alt=""
              className="absolute bottom-[-2px] left-0 w-[600px] h-10"
            />
          </span>{" "}
          Our Product
        </>
      );
      setHeaderText(
        "You have an idea, we have the talent and resources to turn it into a reality. Digital Gravity is a globally recognized digital agency elevating brands."
      );
      setHeaderButton("Let’s Explore");
    }
  }, [currentSlide]);

  return (
    <div className="body relative">
      <div className="">
        <Navbar setVisibleRight={setVisibleRight} />

        <div className="grid grid-cols-2 sm1:grid-cols-1 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:gap-[50px] md1:gap-[37px] lg:gap-[20px] sm:gap-[63px] xl:gap-0 text-[80px] xs:gap-[63px] gap-[223px] sm1:gap-[50px] sm1:w-[530px] mx-auto md:w-[630px] md1:w-[822px] lg:w-[966px] xl:w-[1190px] sm:w-[450px] xs:w-[300px] w-[1440px] xs:mt-[55px] sm:mt-[55px] md:mt-[64px] mt-[95px]">
          <div className="xl:w-[516px] xs:w-[300px] sm:w-[450px] sm1:w-[530px] xl:ml-[1px] md:w-[630px] lg:w-[448px] md1:w-[380px] w-[606px] h-[516px] mx-auto">
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6 }}
              className="md:text-[71px] sm1:text-[68px]  md1:text-[60px] md1:leading-[72px] lg:text-[71px] sm:text-[55px] sm:leading-[67px] xs:text-[35px] xl:text-[80px] text-[80px] xs:leading-[48px] xl:leading-[86px] font-bold leading-[86px] text-[#EEEEEE]">
              {headerBrand}
            </motion.p>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              className="text-[18px] xs:text-[16px] font-normal mt-[24px] xs:text-justify md1:text-justify lg:text-justify xl:text-justify text-left md:text-justify sm:text-justify sm1:text-justify">
              {headerText}
            </motion.p>
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
              className="mt-[40px] w-[193px] h-[56px] text-[#FFFFFF] bg-[#2244FF] rounded-[4px] xs:text-[14px] text-[18px] font-semibold flex justify-center items-center gap-2 hh">
              {headerButton}
              <MdArrowOutward className="text-[22px] animate-bounce" />
            </motion.button>
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.9 }}
            className="md:w-[630px] lg:w-[467px] xl:w-[585px] md1:w-[390px] sm:w-[450px] xs:w-[300px] w-[606px] mx-auto sm1:w-[530px]">
            <div className="hidden xs:flex xs:w-[280px] sx:mx-4 sm:w-[420px] sm1:w-[510px] sm:flex sm1:flex md:flex justify-end items-center gap-8 mt-[-163px] xs:mt-[-202px]">
              <a
                className="button arrow"
                onClick={() => sliderRef.current.slickPrev()}></a>
              <a
                className="button arrow-right"
                onClick={() => sliderRef.current.slickNext()}></a>
            </div>
            <Slider ref={sliderRef} {...settings}>
              {dummyData.map((item, i) => (
                <div key={i}>
                  <div className="flex justify-end items-end">
                    <div
                      className="xl:w-[585px] md1:w-[390px] md1:h-[371px] sm:w-[450px] sm1:w-[530px] xs:w-[300px] lg:w-[467px] w-[606px] xl:h-[472px] md:w-[630px] md:h-[562px] sm:h-[420px] lg:h-[406px] h-[512px] xs:h-[262px] rounded-[6px] lg:rounded-[10px] flex justify-center items-center"
                      style={{
                        background:
                          "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)",
                      }}>
                      <div className="xl:w-[558px] md:w-[593px] sm1:w-[510px] md:h-[523px] md1:w-[363px] xs:w-[280px] sm:w-[420px] sm:h-[385px] lg:w-[441px] md1:h-[336px] lg:h-[373px] w-[572.31px] xl:h-[434px] xs:h-[237px] h-[480px] rounded-[5px]">
                        {" "}
                        <img
                          src={item?.img}
                          alt=""
                          className="w-full h-full object-fill"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            <div className="xs:hidden sm:hidden md:hidden sm1:hidden flex justify-end items-center gap-8 mt-[-23px] xl:mt-[-40px] lg:mt-[-46px]">
              <a
                className="button arrow"
                onClick={() => sliderRef.current.slickPrev()}></a>
              <a
                className="button arrow-right"
                onClick={() => sliderRef.current.slickNext()}></a>
            </div>
          </motion.div>

          <motion.div
            initial={{ x: "-100vw" }}
            animate={{ x: "-50%", left: "50%" }}
            transition={{ duration: 1 }}
            className="absolute bottom-[3rem] left-1/2 -translate-x-1/2"
            id="service">
            <SectionHeader title={"Services"} />
          </motion.div>
        </div>
      </div>
      <SidebarNav
        visibleRight={visibleRight}
        setVisibleRight={setVisibleRight}
      />
    </div>
  );
};

export default HeroSection;
