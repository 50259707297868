import React from "react";
import { pricingData } from "../../../Components/Common/staticData";
import PricingCard from "../../../Components/Common/PricingCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const DesignTab = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 668,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="pb-[200px] lg:pb-[200px]">
      <div className="md:hidden xs:w-[380px] xs:hidden sm:hidden sm:w-[450px] sm1:hidden md:w-[630px] md1:w-[822px] lg:w-[966px] xl:w-[1190px] w-[1024px] mx-auto flex justify-center lg:justify-normal items-center md:gap-[24px] gap-[126px] sm1:gap-[10px] md1:gap-[69px] xs:mt-[60px] sm:mt-[60px] sm1:mt-[70px] md:mt-[84px] md1:mt-[87px] lg:mt-[87px] xl:mt-[87px] mt-[87px]">
        <div className="xs:hidden sm:hidden sm1:hidden md:flex md1:flex lg:flex flex xl:flex flex-col space-y-[24px] mt-12">
          <p className="text-[17px] font-semibold text-nowrap">
            All limited links
          </p>
          <p className="text-[17px] font-semibold text-nowrap">
            Own analytics platform
          </p>
          <p className="text-[17px] font-semibold text-nowrap">Chat support</p>
          <p className="text-[17px] font-semibold text-nowrap">
            Number of users
          </p>
          <p className="text-[17px] font-semibold text-nowrap">
            Optimize hashtags
          </p>
        </div>
        <div className="grid md:hidden lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 xs:grid-cols-1 sm1:grid-cols-1 md1:grid-cols-3 grid-cols-3 justify-items-end md1:gap-[65px] gap-[14px] xl:gap-[14px] lg:gap-[53px]">
          {pricingData?.map((priceData, i) => {
            return <PricingCard key={i} priceData={priceData} />;
          })}
        </div>
      </div>
      <div className="lg:hidden xl:hidden xs:flex sm:flex mdl:hidden sm1:flex hidden xs:w-[300px] sm:w-[450px] sm1:w-[530px] md:w-[630px] md1:w-[822px] lg:w-[966px] xl:w-[1190px] w-[1024px] mx-auto md:flex justify-center lg:justify-normal items-center md:gap-[24px] sm:gap-[10px] gap-[126px] sm1:gap-[10px] md1:gap-[69px] xs:gap-[16px]  xs:mt-[60px] sm:mt-[60px] sm1:mt-[70px] md:mt-[84px] md1:mt-[87px] lg:mt-[87px] xl:mt-[87px] mt-[87px]">
        <div className="hidden xs:hidden sm:hidden sm1:flex md:flex flex-col xs:space-y-[10px] sm1:space-y-[20px] space-y-[24px] mt-12">
          <p className="text-[17px] sm1:text-[15px] sm:text-[14px] xs:text-[12px] font-semibold text-nowrap">
            All limited links
          </p>
          <p className="text-[17px] sm1:text-[15px] sm:text-[14px] xs:text-[12px] font-semibold text-nowrap">
            Own analytics platform
          </p>
          <p className="text-[17px] sm1:text-[15px] sm:text-[14px] xs:text-[12px] font-semibold text-nowrap">
            Chat support
          </p>
          <p className="text-[17px] sm1:text-[15px] sm:text-[14px] xs:text-[12px] font-semibold text-nowrap">
            Number of users
          </p>
          <p className="text-[17px] sm1:text-[15px] sm:text-[14px] xs:text-[12px] font-semibold text-nowrap">
            Optimize hashtags
          </p>
        </div>
        <div className="md:w-[470px] xs:w-[260px] sm:w-[480px] sm1:w-[400px] md:block xl:hidden lg:hidden md1:hidden">
          <Slider {...settings}>
            {pricingData?.map((priceData, i) => {
              return <PricingCard key={i} priceData={priceData} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default DesignTab;
