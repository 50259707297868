import React, { useState } from "react";
import SectionHeader from "../../../Components/Common/SectionHeader";
import DesignTab from "./DesignTab";
import gradientBg from "../../../assets/portfolio.png";

const PortfolioSection = () => {
  const [activeTab, setActiveTab] = useState("Design");

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="servicess">
      <div
        className="sm1:w-[530px] md:w-[630px] md1:w-[822px] lg:w-[966px] xl:w-[1190px] sm:w-[450px] xs:w-[300px] w-[1232px] h-[749px] mx-auto relative pt-10"
        id="work">
        <SectionHeader title={"Portfolio"} />

        <div
          className="xs:hidden sm:hidden sm1:hidden md:w-[630px] w-[792px] h-[58.1px] rounded-[10px] mx-auto mt-[32px] border border-[#FFFFFF26] grid grid-cols-4"
          style={{
            background:
              "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)",
          }}>
          <div
            className={`${
              activeTab === "Design"
                ? "text-[#EEEEEE] border-0 border-r border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } md:w-[158px] w-[198px] text-[17px] font-semibold  text-[#EEEEEE] h-full flex justify-center items-center rounded-l-[9px] cursor-pointer`}
            style={{
              background:
                activeTab === "Design"
                  ? " linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Design")}>
            Design
          </div>
          <div
            className={`${
              activeTab === "Development"
                ? "text-[#EEEEEE] border-0 border-r border-l border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } lg:w-[198px] text-[17px] font-semibold  text-[#EEEEEE] h-full flex justify-center items-center cursor-pointer `}
            style={{
              background:
                activeTab === "Development"
                  ? " linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Development")}>
            Development
          </div>
          <div
            className={`${
              activeTab === "SMM"
                ? "text-[#EEEEEE] border-0 border-r border-l border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } lg:w-[198px] text-[17px] font-semibold  text-[#EEEEEE] h-full flex justify-center items-center cursor-pointer `}
            style={{
              background:
                activeTab === "SMM"
                  ? " linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("SMM")}>
            SMM
          </div>
          <div
            className={`${
              activeTab === "SEO"
                ? "text-[#EEEEEE] border-0 border-r border-l border-[#FFFFFF26] rounded-r-[9px]"
                : "text-[#b5b5b5]"
            } lg:w-[198px] text-[17px] font-semibold  text-[#EEEEEE] h-full flex justify-center items-center cursor-pointer `}
            style={{
              background:
                activeTab === "SEO"
                  ? " linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("SEO")}>
            SEO
          </div>
        </div>
        {/* mobile device */}
        <div
          className="xs:w-[300px] sm:w-[360px] sm1:w-[447px] h-[47px] rounded-[10px] mx-auto mt-[20px] lg:mt-[32px] border border-[#FFFFFF26] mdl:hidden xl:hidden lg:hidden hidden xs:flex sm:flex sm1:flex"
          style={{
            background:
              "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)",
          }}>
          <div
            className={`${
              activeTab === "Design"
                ? "text-[#EEEEEE] border-0 border-r border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } xs:w-[70px] sm:w-[89px] sm1:w-[110px] sm:text-[16px] xs:text-[15px] text-[16px] font-semibold  text-[#EEEEEE] h-full flex justify-center items-center rounded-l-[9px] cursor-pointer`}
            style={{
              background:
                activeTab === "Design"
                  ? " linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Design")}>
            Design
          </div>
          <div
            className={`${
              activeTab === "Development"
                ? "text-[#EEEEEE] border-0 border-r border-l border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } xs:w-[123px] w-[140px] sm:text-[16px] xs:text-[15px] text-[16px] font-semibold text-[#EEEEEE] h-full flex justify-center items-center cursor-pointer `}
            style={{
              background:
                activeTab === "Development"
                  ? " linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Development")}>
            Development
          </div>
          <div
            className={`${
              activeTab === "SMM"
                ? "text-[#EEEEEE] border-0 border-r border-l border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } xs:w-[57px] sm:w-[70px] sm1:w-[106px] sm:text-[16px] xs:text-[15px] text-[16px] font-semibold  text-[#EEEEEE] h-full flex justify-center items-center cursor-pointer `}
            style={{
              background:
                activeTab === "SMM"
                  ? " linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("SMM")}>
            SMM
          </div>
          <div
            className={`${
              activeTab === "SEO"
                ? "text-[#EEEEEE] border-0 border-r border-l border-[#FFFFFF26] rounded-r-[9px]"
                : "text-[#b5b5b5]"
            } xs:w-[50px] sm:w-[59px] sm1:w-[89px] sm:text-[16px] xs:text-[15px] text-[16px] font-semibold  text-[#EEEEEE] h-full flex justify-center items-center cursor-pointer `}
            style={{
              background:
                activeTab === "SEO"
                  ? " linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("SEO")}>
            SEO
          </div>
        </div>
        {activeTab === "Design" && <DesignTab />}
        {activeTab === "Development" && <DesignTab />}
        {activeTab === "SMM" && <DesignTab />}
        {activeTab === "SEO" && <DesignTab />}
      </div>
    </div>
  );
};

export default PortfolioSection;
