import React from "react";
import facebook from "../../assets/facebook.svg";
import insta from "../../assets/insta.svg";
import linkedIn from "../../assets/linkedin.svg";
import twitter from "../../assets/twitter.svg";
import {
  FaFacebook,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaXTwitter,
} from "react-icons/fa6";
const SocialIcons = () => {
  return (
    <div className="flex items-center justify-start gap-[19px]">
      <div className="w-[30px] lg:w-[33px] h-[30px] lg:h-[33px] rounded-[33px] border-[2.53px] border-[#FFFFFF] flex justify-center items-center transition-all duration-300 hover:bg-[#FFFFFF] group">
        <FaFacebookF className="h-[13px] w-[13px] transition-all duration-300 group-hover:text-[#396afd]" />
      </div>
      <div className="w-[30px] lg:w-[33px] h-[30px] lg:h-[33px] rounded-[33px] border-[2.53px] border-[#FFFFFF] flex justify-center items-center transition-all duration-300 hover:bg-[#FFFFFF] group">
        <FaXTwitter
          className="h-[13px] w-[13px] transition-all duration-300
        group-hover:text-[#396afd]"
        />
      </div>
      <div className="w-[30px] lg:w-[33px] h-[30px] lg:h-[33px] rounded-[33px] border-[2.53px] border-[#FFFFFF] flex justify-center items-center transition-all duration-300 hover:bg-[#FFFFFF] group">
        <FaInstagram
          className="h-[13px] w-[13px] transition-all duration-300
        group-hover:text-[#396afd]"
        />
      </div>
      <div className="w-[30px] lg:w-[33px] h-[30px] lg:h-[33px] rounded-[33px] border-[2.53px] border-[#FFFFFF] flex justify-center items-center transition-all duration-300 hover:bg-[#FFFFFF] group">
        <FaLinkedin
          className="h-[13px] w-[13px] transition-all duration-300
        group-hover:text-[#396afd]"
        />
      </div>
    </div>
  );
};

export default SocialIcons;
