import gradientBg from "../../../assets/services.png";
import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Mousewheel, Pagination } from "swiper/modules";
import { serviceSectionData } from "../../../Components/Common/staticData";
import { motion } from "framer-motion";
import designimg1 from "../../../assets/design.png";
import devimg from "../../../assets/development.png";
import lonimg from "../../../assets/launch.png";
import promoimg from "../../../assets/promote.png";
//servicesImage
import designImage1 from "../../../assets/serviceImage/design2.png";
import designImage2 from "../../../assets/serviceImage/design1.png";
import developImage1 from "../../../assets/serviceImage/development1.png";
import developImage2 from "../../../assets/serviceImage/development2.png";
import developImage3 from "../../../assets/serviceImage/development3.png";
import launchImg1 from "../../../assets/serviceImage/launch1.png";
import launchImg2 from "../../../assets/serviceImage/lauch2.png";
import launchImg3 from "../../../assets/serviceImage/launch3.png";
import promoteImg1 from "../../../assets/serviceImage/promote1.png";
import promoteImg2 from "../../../assets/serviceImage/promote2.png";

const ServicesPart = () => {
  const [textSize, setTextSize] = useState();
  const [activeSlideText, setActiveSlideText] = useState("Website Development");
  const [activeTab, setActiveTab] = useState("Development");
  const swiperRef = useRef(null);

  const handleScroll = (event) => {
    const newTextSize =
      event.deltaY > 0
        ? Math.max(22, textSize - 2)
        : Math.min(34, textSize + 2);
    setTextSize(newTextSize);
  };

  const handleSlideChange = (swiper) => {
    const activeSlide = swiper.slides[swiper.activeIndex];
    const slideText = activeSlide?.querySelector(".slide-text").innerText;
    setActiveSlideText(slideText);
  };

  const toggleTab = (tab) => {
    setActiveTab(tab);
    const tabToServiceMap = {
      Design: "Brand Identity Design",
      Development: "Website Development",
      Launch: "Hosting Management",
      Promote: "Search Engine Optimization (SEO)",
    };
    const serviceToSlideIndex = serviceSectionData.findIndex(
      (service) => service.service === tabToServiceMap[tab]
    );
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(serviceToSlideIndex);
    }
  };

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const serviceToSlideIndex = serviceSectionData.findIndex(
        (service) => service.service === "Website Development"
      );
      swiperRef.current.swiper.slideTo(serviceToSlideIndex);
    }
  }, []);

  useEffect(() => {
    if (["Brand Identity Design", "UI & UX Design"].includes(activeSlideText)) {
      setActiveTab("Design");
    } else if (
      [
        "Website Development",
        "Mobile Application Development",
        "E-commerce Store Management",
      ].includes(activeSlideText)
    ) {
      setActiveTab("Development");
    } else if (
      [
        "Hosting Management",
        "Domain Purchase",
        "Business Email Setup",
      ].includes(activeSlideText)
    ) {
      setActiveTab("Launch");
    } else if (
      [
        "Search Engine Optimization (SEO)",
        "Social Media Marketing (SMM)",
      ].includes(activeSlideText)
    ) {
      setActiveTab("Promote");
    }
  }, [activeSlideText]);

  const currentService = serviceSectionData.find(
    (service) => service.service === activeSlideText
  );
  return (
    <>
      <div className="md1:block md:block lg:block xl:block hidden sm:hidden sm1:hidden xs:hidden h-[889px] py-[70px] rounded-[10px] servicess relative ">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.1 }}
          className="xs:hidden sm:hidden xl:w-[792px] lg:w-[792px] md1:w-[692px] md:w-[592px] sm1:w-[400px] sm:w-[300px] w-[792px] h-[58.1px] rounded-[10px] mx-auto mt-[32px] border border-[#FFFFFF26] flex"
          style={{
            background:
              "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)",
          }}>
          <div
            className={`${
              activeTab === "Design"
                ? "text-[#EEEEEE] border-0 border-r border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } sm1:w-[100px] md:w-[148px] md1:w-[171px] w-[198px] text-[17px] font-semibold text-[#EEEEEE] h-full flex justify-center items-center rounded-l-[9px] cursor-pointer`}
            style={{
              background:
                activeTab === "Design"
                  ? "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Design")}>
            Design
          </div>
          <div
            className={`${
              activeTab === "Development"
                ? "text-[#EEEEEE] border-0 border-r border-l border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } sm1:w-[150px] md:w-[148px] md1:w-[171px] w-[198px] text-[17px] font-semibold text-[#EEEEEE] h-full flex justify-center items-center cursor-pointer`}
            style={{
              background:
                activeTab === "Development"
                  ? "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Development")}>
            Development
          </div>
          <div
            className={`${
              activeTab === "Launch"
                ? "text-[#EEEEEE] border-0 border-r border-l border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } sm1:w-[96px] md:w-[148px] md1:w-[171px] w-[198px] text-[17px] font-semibold text-[#EEEEEE] h-full flex justify-center items-center cursor-pointer`}
            style={{
              background:
                activeTab === "Launch"
                  ? "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Launch")}>
            Launch
          </div>
          <div
            className={`${
              activeTab === "Promote"
                ? "text-[#EEEEEE] border-0 border-r border-l border-[#FFFFFF26] rounded-r-[9px]"
                : "text-[#b5b5b5]"
            } sm1:w-[109px] md:w-[148px] md1:w-[171px] w-[198px] text-[17px] font-semibold text-[#EEEEEE] h-full flex justify-center items-center cursor-pointer`}
            style={{
              background:
                activeTab === "Promote"
                  ? "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Promote")}>
            Promote
          </div>
        </motion.div>

        <div
          className="grid grid-cols-2 sm1:grid-cols-1 xs:grid-cols-1 sm:grid-cols-1 justify-items-center gap-[40px] items-center mt-[40px] sm1:w-[530px] mx-auto md:w-[630px] md1:w-[700px] lg:w-[822px] xl:w-[946px] sm:w-[380px] xs:w-[300px] w-[1340px] sm:hidden xs:hidden sm1:hidden"
          onWheel={handleScroll}>
          <div className="flex justify-normal items-center relative">
            <a id="scroll-btn"></a>
            <div className="h-[277.95px]  lg:h-[255px] bg-[#adadad] w-[1px] my-[192px] mr-[25px] ml-5"></div>

            <div className="my-10">
              <Swiper
                ref={swiperRef}
                direction={"vertical"}
                slidesPerView={5}
                centeredSlides={true}
                spaceBetween={30}
                mousewheel={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Mousewheel, Pagination]}
                className="mySwiper h-[277.95px] lg:h-[255px] w-[507px] md:w-[350px] md1:w-[390px] lg:w-[390px]"
                onSlideChange={handleSlideChange}>
                {serviceSectionData.map((e, i) => (
                  <SwiperSlide key={i} className="swiper-slide-custom">
                    <p className="slide-text text-nowrap">{e.service}</p>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className="">
            <img
              src={currentService?.img}
              alt=""
              width={currentService?.width}
            />

            <p className="text-[16px] font-normal mt-[22px]  text-justify">
              {currentService?.description}
            </p>

            <button className="text-[18px] font-semibold text-[#FFFFFF] bg-[#2244FF] w-[136px] h-[43px] rounded-[4px] mt-[17px] bn2">
              {currentService?.buttonText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesPart;
