import React from "react";
import SectionHeader from "../../../Components/Common/SectionHeader";
import aboutImage1 from "../../../assets/aboutImage/1.png";
import aboutImage2 from "../../../assets/aboutImage/2.png";
import aboutImage3 from "../../../assets/aboutImage/3.png";
import aboutImage4 from "../../../assets/aboutImage/4.png";

const About = () => {
  return (
    <div
      className={`xs:pt-[30px] sm1:pt-[120px] sm:pt-[50px] pt-[160px] lg:pt-[130px] md:w-[630px] lg:w-[966px] xl:w-[1190px] sm:w-[450px] xs:w-[300px] sm1:w-[530px] md1:w-[822px] w-[1232px] xs:h-[440px] sm:h-[630px] sm1:h-[630px] md:h-[840px] h-[1050px] mx-auto`}
      id="about">
      <div className="xs:w-[280px] sm1:w-[430px] sm:w-[400px] w-[764px] md:w-[493px] mx-auto">
        <SectionHeader title={"About us"} />
        <h2 className=" sm:text-[24px] xs:text-[18px] xs:leading-[20px] sm:leading-[30px] sm1:text-[24px] sm1:leading-[34px] text-[54px] md:text-[34px] md1:text-[46px] font-semibold leading-[65px] text-center mt-[70px] sm:mt-[30px] sm1:mt-[30px] md:mt-[50px] md:leading-[42px] xs:mt-[10px]">
          Lorem Ipsum is simply dummy text of the printing.
        </h2>
      </div>

      <div className="mt-[58px] xs:mt-[24px] sm:mt-[30px] sm1:mt-[32px] flex justify-between items-start">
        <div className="relative">
          {" "}
          <div className="xs:w-[80px] xs:h-[80px] sm:w-[130px] sm1:w-[160px] md:w-[188px] md1:w-[220px] w-[262px] sm:h-[130px] sm1:h-[160px] md:h-[188px] md1:h-[220px] h-[262px] rounded-[13px]">
            <img
              src={aboutImage3}
              alt=""
              className="h-full w-full object-fill rounded-[13px]"
            />
          </div>
          <div className="xs:w-[80px] xs:h-[80px] sm:w-[130px] sm1:w-[160px] md:w-[188px] md1:w-[220px] w-[262px] sm:h-[130px] sm1:h-[160px] md:h-[188px] md1:h-[220px] h-[262px] rounded-[13px] absolute sm:top-[7rem] sm:left-[4rem] sm1:top-[8rem] md:top-[9rem] md1:top-[11rem] top-40 sm1:left-[5rem] md:left-[6rem] md1:left-[11rem] left-48 xs:top-[5rem] xs:left-[3rem]">
            <img
              src={aboutImage4}
              alt=""
              className="h-full w-full object-fill rounded-[13px]"
            />
          </div>
        </div>
        <p className="sm1:text-[16px] md:text-[18px] lg:text-[22px] md1:text-[21px] text-[24px] font-normal md:w-[231px] md1:w-[327px] sm1:w-[192px] sm:w-[168px] xs:w-[120px] sm:text-[14px] w-[626px] mx-auto text-center xs:mt-[0px] xs:text-[10px]">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's
        </p>
        <div className="relative">
          <div className="xs:w-[80px] xs:h-[80px] sm:w-[130px] sm1:w-[160px] md:w-[188px] md1:w-[220px] w-[262px] sm:h-[130px] sm1:h-[160px] md:h-[188px] md1:h-[220px] h-[262px] rounded-[13px]">
            <img
              src={aboutImage1}
              alt=""
              className="h-full w-full object-fill rounded-[13px]"
            />
          </div>
          <div className="xs:w-[80px] xs:h-[80px] sm:w-[130px] sm1:w-[160px] md:w-[188px] md1:w-[220px] w-[262px] sm:h-[130px] sm1:h-[160px] md:h-[188px] md1:h-[220px] h-[262px] rounded-[13px]  absolute sm:top-[7rem] sm:right-[4rem] sm1:top-[8rem] md:top-[9rem] md1:top-[11rem] sm1:right-[4rem] md:right-[5rem] md1:right-[11rem] xs:top-[5rem] xs:right-[4rem] top-[10.5rem] right-48">
            <img
              src={aboutImage2}
              alt=""
              className="h-full w-full object-fill rounded-[13px]"
            />
          </div>
        </div>
      </div>
      {/* small device */}
      {/* <div className="w-[326px] lg:hidden mt-[40px] relative">
        <div className="w-[195px] h-[195px] rounded-[10px] absolute right-[-30px]">
          <img
            src={aboutImage1}
            alt=""
            className="h-full w-full object-fill rounded-[10px]"
          />
        </div>
        <div className="w-[195px] h-[195px] rounded-[10px] absolute right-24 top-32">
          <img
            src={aboutImage2}
            alt=""
            className="h-full w-full object-fill rounded-[10px]"
          />
        </div>
      </div> */}
      {/* small device end */}
    </div>
  );
};

export default About;
