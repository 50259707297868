import React from "react";
import SectionHeader from "../../../Components/Common/SectionHeader";
import { MdArrowOutward } from "react-icons/md";

const ContactSection = () => {
  return (
    <div
      className="bg-[#131217] sm1:w-[530px] mx-auto md:w-[630px] md1:w-[822px] lg:w-[966px] xl:w-[1190px] sm:w-[450px] xs:w-[300px] w-[814px] lg:pt-[31px] pt-[100px] pb-[200px]"
      id="contact">
      <SectionHeader title={"Contact Us"} />
      <form className="xs:pt-[40px] pt-[60px] sm1:w-[530px] mx-auto md:w-[630px] md1:w-[822px] lg:w-[814px] xl:w-[814px] sm:w-[450px] xs:w-[300px] w-[814px]">
        <div className="grid xs:grid-cols-1 sm:grid-cols-1 grid-cols-2 md:gap-x-[40px]">
          <div className="">
            <label className="text-[#D7D7D7] text-[16px] font-normal leading-6">
              First name
            </label>
            <div className="mt-2">
              <input
                type="text"
                className="xl:w-[396px] w-[396px] xs:w-[300px] sm:w-[450px] sm1:w-[255px] md:w-[310px] xs:h-[45px] h-[56px] bg-transparent rounded-[4px] border border-[#39393A] px-[5px]"
              />
            </div>
          </div>

          <div className="mt-0 sm:mt-2">
            <label className="text-[#D7D7D7] text-[16px] font-normal leading-6">
              Your Email
            </label>
            <div className="mt-2">
              <input
                type="text"
                className="px-[5px] xl:w-[396px] w-[396px] xs:w-[300px] sm:w-[450px] sm1:w-[255px] md:w-[310px] xs:h-[45px] h-[56px] bg-transparent rounded-[4px] border border-[#39393A]"
              />
            </div>
          </div>

          <div className="mt-[17px]">
            <label className="text-[#D7D7D7] text-[16px] font-normal leading-6">
              Your Phone Number
            </label>
            <div className="mt-2">
              <input
                type="text"
                className="px-[5px]  xl:w-[396px] w-[396px] xs:w-[300px] sm:w-[450px] sm1:w-[255px] md:w-[310px] xs:h-[45px] h-[56px] bg-transparent rounded-[4px] border border-[#39393A]"
              />
            </div>
          </div>

          <div className="mt-[17px]">
            <label className="text-[#D7D7D7] text-[16px] font-normal leading-6">
              Budget
            </label>
            <div className="mt-2">
              <select className="px-[5px]  xl:w-[396px] w-[396px] xs:w-[300px] sm:w-[450px] sm1:w-[255px] md:w-[310px] xs:h-[45px] h-[56px] bg-transparent rounded-[4px] border border-[#39393A]">
                <option className="bg-[#202021]">United States</option>
                <option className="bg-[#202021]">Canada</option>
                <option className="bg-[#202021]">Mexico</option>
              </select>
            </div>
          </div>
        </div>
        <div className="mt-[24px] lg:mt-[17px]">
          <label className="text-[#D7D7D7] text-[16px] font-normal leading-6">
            Your Message
          </label>
          <div className="mt-2">
            <textarea className="px-[5px] py-[5px] xl:w-[800px] w-[800px] xs:w-[300px] sm:w-[450px] sm1:w-[530px] md:w-[640px] xs:h-[140px] h-[232px] bg-transparent rounded-[4px] border border-[#39393A]" />
          </div>
        </div>

        <div className="mt-[32px] flex items-center justify-center gap-x-6">
          <button
            type="submit"
            className="w-[192px] h-[48px] rounded-[4px] bg-[#2244FF] flex justify-center items-center mx-auto gap-1 hh">
            <p className="text-[16px] font-semibold">Get in touch</p>
            <MdArrowOutward className="text-[22px] animate-bounce" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactSection;
