import React from "react";

const SectionHeader = ({ title }) => {
  return (
    <h1
      className={`section-header z-10 text-[#EEEEEE] ${
        title == "Contact"
          ? "sm:text-[36px] lg:text-[70px] text-[80px] font-bold"
          : "sm:text-[39px] lg:text-[64px] text-[80px] md1:text-[52px] md:text-[64px] sm1:text-[51px] xs:text-[34px]"
      } text-wrap text-center font-bold leading-[84.75px]`}>
      {title}
    </h1>
  );
};

export default SectionHeader;
