import React, { useState, useRef } from "react";
import { DesignPortfolioData } from "../../../Components/Common/staticData";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const DesignTab = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const sliderRef = useRef(null); // Reference to the Slider

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };

  return (
    <>
      <div className="xs:hidden sm:hidden sm1:hidden pt-[52px] pb-[79px] sm1:w-[530px] md:w-[630px] md1:w-[822px] lg:w-[966px] xl:w-[1190px] sm:w-[450px] xs:w-[300px] w-[1232px] mx-auto flex md1:gap-[45px] lg:gap-[55px] md:gap-[64px] gap-[90px]">
        <div className="md:grid-cols-3 grid grid-cols-4 lg:gap-x-[12px] md1:gap-x-[14px] md:gap-x-[42px] gap-x-[25px] md:h-[400px] md1:h-[500px]">
          {DesignPortfolioData?.map((project, i) => (
            <div
              key={i}
              className={`mt-[16px] `}
              onClick={() => handleImageClick(i)}>
              <div
                className={`md:w-[80px] md1:w-[83px] lg:w-[100px] w-[116px] md:h-[80px] md1:h-[83px] lg:h-[100px] h-[110px] rounded-[14px] ${
                  selectedImageIndex === i
                    ? "border-[5px] border-[#2244FF] rounded-[12px]"
                    : ""
                }`}>
                <img
                  src={project?.img[0].src}
                  alt=""
                  className="h-full w-full object-fill rounded-[14px]"
                />
              </div>
              <p
                className={`${
                  selectedImageIndex === i ? "text-white" : "text-[#888888]"
                } text-[14px] font-semibold mt-[8px] text-nowrap`}>
                {project?.project_title}
              </p>
            </div>
          ))}
        </div>
        <div className="mx-auto md:w-[300px] md1:w-[400px] lg:w-[470px] w-[606px] mt-[20px]">
          {selectedImageIndex !== null && (
            <div className="relative">
              <div
                style={{
                  position: "absolute",
                  top: "30%",
                  left: "0",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
                onClick={() => sliderRef.current.slickPrev()}
                className="cursor-pointer h-10 w-10 bg-white rounded-full flex justify-center items-center ml-5">
                <MdKeyboardArrowLeft className="text-black text-3xl" />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "30%",
                  right: "0",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
                onClick={() => sliderRef.current.slickNext()}
                className="cursor-pointer h-10 w-10 bg-white rounded-full flex justify-center items-center mr-5">
                <MdKeyboardArrowRight className="text-black text-3xl" />
              </div>
              <Slider
                {...settings}
                ref={sliderRef} // Assigning ref to the Slider
                className="md:w-[300px] md1:w-[400px] lg:w-[470px] w-[606px] mx-auto md:h-[200px] md1:h-[230px] lg:h-[298px] h-[307px] rounded-[12px] border-white">
                {DesignPortfolioData[selectedImageIndex].img?.map((e, i) => (
                  <div key={i}>
                    <div className="md:w-[300px] md1:w-[400px] lg:w-[470px] w-[606px] mx-auto md:h-[200px] md1:h-[230px] lg:h-[298px] h-[307px] rounded-[12px]">
                      <img
                        src={e.src}
                        alt=""
                        className="w-full h-full object-fill rounded-[12px]"
                      />
                    </div>
                  </div>
                ))}
              </Slider>
              <p className="text-[17px] font-semibold mt-[28px]">
                {DesignPortfolioData[selectedImageIndex]?.project_title}
              </p>
              <p className="md:text-[15px] text-[16px] font-normal mt-[6px]">
                {DesignPortfolioData[selectedImageIndex]?.project_description}
              </p>
              <div className="mt-[24px]">
                <button
                  type="submit"
                  className="w-[136px] h-[43px] rounded-[4px] bg-[#2244FF] bn2">
                  <p className="text-[16px] font-semibold">Learn More</p>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* mobile */}
      <div className="pt-[34px] lg:pt-[52px] pb-0 lg:pb-[79px] sm:w-[450px] xs:w-[300px] sm1:w-[530px] mx-auto lg:hidden gap-[90px] hidden xs:block sm:block sm1:block">
        <div className="lg:hidden md1:hidden xl:hidden flex overflow-x-auto gap-x-[25px] xs:gap-x-[14px] sm1:gap-x-[14px] sm:w-[450px] xs:w-[300px] sm1:w-[530px] mx-auto">
          {DesignPortfolioData?.map((project, i) => (
            <div
              key={i}
              className={`mt-[16px]`}
              onClick={() => handleImageClick(i)}>
              <div
                className={`w-[71px] h-[71px] rounded-[7px] ${
                  selectedImageIndex === i
                    ? "border-[5px] border-[#2244FF]"
                    : ""
                }`}>
                <img
                  src={project?.img[0].src}
                  alt=""
                  className="h-full w-full object-fill rounded-[7px]"
                />
              </div>
              <p className="text-[7px] xs:text-[12px] sm1:text-[12px] sm:text-[12px] font-semibold xs:mt-[5px] sm:mt-[5px] sm1:mt-[5px] mt-[11px] leading-[16px] text-[#888888]">
                {DesignPortfolioData[selectedImageIndex]?.project_title}
              </p>
            </div>
          ))}
        </div>
        <div className="mx-auto sm1:w-[520px] mt-[20px]">
          {selectedImageIndex !== null && (
            <div className="relative">
              <div
                style={{
                  position: "absolute",
                  top: "30%",
                  left: "0",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
                onClick={() => sliderRef.current.slickPrev()}
                className="cursor-pointer h-10 w-10 bg-white rounded-full flex justify-center items-center ml-5">
                <MdKeyboardArrowLeft className="text-black text-3xl" />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "30%",
                  right: "0",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
                onClick={() => sliderRef.current.slickNext()}
                className="cursor-pointer h-10 w-10 bg-white rounded-full flex justify-center items-center mr-5">
                <MdKeyboardArrowRight className="text-black text-3xl" />
              </div>
              <Slider
                {...settings}
                ref={sliderRef} // Assigning ref to the Slider
                className="sm1:w-[520px] mx-auto xs:h-[190px] h-[243px] rounded-[12px] border-white">
                {DesignPortfolioData[selectedImageIndex].img?.map((e, i) => (
                  <div key={i}>
                    <div className="mx-auto sm1:w-[520px] xs:h-[190px] h-[243px] rounded-[12px]">
                      <img
                        src={e.src}
                        alt=""
                        className="w-full h-full object-fill rounded-[12px]"
                      />
                    </div>
                  </div>
                ))}
              </Slider>
              <p className="text-[17px] font-semibold mt-[28px]">
                {DesignPortfolioData[selectedImageIndex]?.project_title}
              </p>
              <p className="text-[16px] xs:text-[15px] font-normal mt-[6px] xs:text-justify sm1:text-justify sm:text-justify">
                {DesignPortfolioData[selectedImageIndex]?.project_description}
              </p>
              <div className="mt-[24px]">
                <button
                  type="submit"
                  className="w-[136px] h-[43px] rounded-[4px] bg-[#2244FF] bn2">
                  <p className="text-[16px] font-semibold">Learn More</p>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DesignTab;
