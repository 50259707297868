import designPortfolioImg1 from "../../assets/ProjectImage/1.png";
import designPortfolioImg2 from "../../assets/ProjectImage/2.png";
import designPortfolioImg3 from "../../assets/ProjectImage/3.png";
import designPortfolioImg4 from "../../assets/ProjectImage/4.png";
import designPortfolioImg5 from "../../assets/ProjectImage/5.png";
import designPortfolioImg6 from "../../assets/ProjectImage/6.png";
import designPortfolioImg7 from "../../assets/ProjectImage/7.png";
import designPortfolioImg8 from "../../assets/ProjectImage/8.png";
import designPortfolioImg9 from "../../assets/ProjectImage/9.png";
import designPortfolioImg10 from "../../assets/ProjectImage/10.png";
import designPortfolioImg11 from "../../assets/ProjectImage/11.png";
import designPortfolioImg12 from "../../assets/ProjectImage/12.png";

//servicesImage
import designImage1 from "../../assets/serviceImage/design2.png";
import designImage2 from "../../assets/serviceImage/design1.png";
import developImage1 from "../../assets/serviceImage/development1.png";
import developImage2 from "../../assets/serviceImage/development2.png";
import developImage3 from "../../assets/serviceImage/development3.png";
import launchImg1 from "../../assets/serviceImage/launch1.png";
import launchImg2 from "../../assets/serviceImage/lauch2.png";
import launchImg3 from "../../assets/serviceImage/launch3.png";
import promoteImg1 from "../../assets/serviceImage/promote1.png";
import promoteImg2 from "../../assets/serviceImage/promote2.png";
export const pricingData = [
  {
    id: 1,
    package_name: "Starter",
    price: "$20",

    color: "#FFFFFF",
    facilities: [
      {
        id: 1,
        name: "",
        name1: "All limited links",
      },
      {
        id: 2,
        name: "",
        name1: "Own analytics platform",
      },
      {
        id: 3,
        name: "",
        name1: "Chat support",
      },
      {
        id: 4,
        name: "1 user",
        name1: "1 user",
      },
      {
        id: 5,
        name: "",
        name1: "Optimize hashtags",
      },
    ],
  },
  {
    id: 2,
    package_name: "Pro",
    price: "$100",
    color: "#FFFFFF",
    facilities: [
      {
        id: 1,
        name: "",
        name1: "All limited links",
      },
      {
        id: 2,
        name: "",
        name1: "Own analytics platform",
      },
      {
        id: 3,
        name: "",
        name1: "Chat support",
      },
      {
        id: 4,
        name: "3 user",
        name1: "3 user",
      },
      {
        id: 5,
        name: "",
        name1: "Optimize hashtags",
      },
    ],
  },
  {
    id: 3,
    package_name: "Enterprise",
    price: "$200",
    color: "#FFFFFF",
    facilities: [
      {
        id: 1,
        name: "",
        name1: "All limited links",
      },
      {
        id: 2,
        name: "",
        name1: "Own analytics platform",
      },
      {
        id: 3,
        name: "",
        name1: "Chat support",
      },
      {
        id: 4,
        name: "Unlimited",
        name1: "Unlimited",
      },
      {
        id: 5,
        name: "",
        name1: "Optimize hashtags",
      },
    ],
  },
];

export const DesignPortfolioData = [
  {
    id: 1,
    img: [
      {
        id: 1,
        src: designPortfolioImg1,
      },
      {
        id: 2,
        src: designPortfolioImg1,
      },
      {
        id: 3,
        src: designPortfolioImg1,
      },
    ],
    project_title: "Project title",
    project_description:
      "Lorem ipsum dolor sit amet consectetur. Natoque dui non convallis sit. Nunc diam tincidunt orci elit erat iaculis. Nulla at quis ut commodo vel vestibulum suscipit convallis. Vitae non turpis et tempus tristique tellus.",
  },
  {
    id: 2,
    img: [
      {
        id: 1,
        src: designPortfolioImg2,
      },
      {
        id: 2,
        src: designPortfolioImg2,
      },
      {
        id: 3,
        src: designPortfolioImg2,
      },
    ],
    project_title: "Project title",
    project_description:
      "Lorem ipsum dolor sit amet consectetur. Natoque dui non convallis sit. Nunc diam tincidunt orci elit erat iaculis. Nulla at quis ut commodo vel vestibulum suscipit convallis. Vitae non turpis et tempus tristique tellus.",
  },
  {
    id: 3,
    img: [
      {
        id: 1,
        src: designPortfolioImg3,
      },
      {
        id: 2,
        src: designPortfolioImg3,
      },
      {
        id: 3,
        src: designPortfolioImg3,
      },
    ],
    project_title: "Project title",
    project_description:
      "Lorem ipsum dolor sit amet consectetur. Natoque dui non convallis sit. Nunc diam tincidunt orci elit erat iaculis. Nulla at quis ut commodo vel vestibulum suscipit convallis. Vitae non turpis et tempus tristique tellus.",
  },
  {
    id: 4,
    img: [
      {
        id: 1,
        src: designPortfolioImg4,
      },
      {
        id: 2,
        src: designPortfolioImg4,
      },
      {
        id: 3,
        src: designPortfolioImg4,
      },
    ],
    project_title: "Project title",
    project_description:
      "Lorem ipsum dolor sit amet consectetur. Natoque dui non convallis sit. Nunc diam tincidunt orci elit erat iaculis. Nulla at quis ut commodo vel vestibulum suscipit convallis. Vitae non turpis et tempus tristique tellus.",
  },
  {
    id: 5,
    img: [
      {
        id: 1,
        src: designPortfolioImg5,
      },
      {
        id: 2,
        src: designPortfolioImg5,
      },
      {
        id: 3,
        src: designPortfolioImg5,
      },
    ],
    project_title: "Project title",
    project_description:
      "Lorem ipsum dolor sit amet consectetur. Natoque dui non convallis sit. Nunc diam tincidunt orci elit erat iaculis. Nulla at quis ut commodo vel vestibulum suscipit convallis. Vitae non turpis et tempus tristique tellus.",
  },
  {
    id: 6,
    img: [
      {
        id: 1,
        src: designPortfolioImg6,
      },
      {
        id: 2,
        src: designPortfolioImg6,
      },
      {
        id: 3,
        src: designPortfolioImg6,
      },
    ],
    project_title: "Project title",
    project_description:
      "Lorem ipsum dolor sit amet consectetur. Natoque dui non convallis sit. Nunc diam tincidunt orci elit erat iaculis. Nulla at quis ut commodo vel vestibulum suscipit convallis. Vitae non turpis et tempus tristique tellus.",
  },
  {
    id: 7,
    img: [
      {
        id: 1,
        src: designPortfolioImg7,
      },
      {
        id: 2,
        src: designPortfolioImg7,
      },
      {
        id: 3,
        src: designPortfolioImg7,
      },
    ],
    project_title: "Project title",
    project_description:
      "Lorem ipsum dolor sit amet consectetur. Natoque dui non convallis sit. Nunc diam tincidunt orci elit erat iaculis. Nulla at quis ut commodo vel vestibulum suscipit convallis. Vitae non turpis et tempus tristique tellus.",
  },
  {
    id: 8,
    img: [
      {
        id: 1,
        src: designPortfolioImg8,
      },
      {
        id: 2,
        src: designPortfolioImg8,
      },
      {
        id: 3,
        src: designPortfolioImg8,
      },
    ],
    project_title: "Project title",
    project_description:
      "Lorem ipsum dolor sit amet consectetur. Natoque dui non convallis sit. Nunc diam tincidunt orci elit erat iaculis. Nulla at quis ut commodo vel vestibulum suscipit convallis. Vitae non turpis et tempus tristique tellus.",
  },
  {
    id: 9,
    img: [
      {
        id: 1,
        src: designPortfolioImg9,
      },
      {
        id: 2,
        src: designPortfolioImg9,
      },
      {
        id: 3,
        src: designPortfolioImg9,
      },
    ],
    project_title: "Project title",
    project_description:
      "Lorem ipsum dolor sit amet consectetur. Natoque dui non convallis sit. Nunc diam tincidunt orci elit erat iaculis. Nulla at quis ut commodo vel vestibulum suscipit convallis. Vitae non turpis et tempus tristique tellus.",
  },
  {
    id: 10,
    img: [
      {
        id: 1,
        src: designPortfolioImg10,
      },
      {
        id: 2,
        src: designPortfolioImg10,
      },
      {
        id: 3,
        src: designPortfolioImg10,
      },
    ],
    project_title: "Project title",
    project_description:
      "Lorem ipsum dolor sit amet consectetur. Natoque dui non convallis sit. Nunc diam tincidunt orci elit erat iaculis. Nulla at quis ut commodo vel vestibulum suscipit convallis. Vitae non turpis et tempus tristique tellus.",
  },
  {
    id: 11,
    img: [
      {
        id: 1,
        src: designPortfolioImg11,
      },
      {
        id: 2,
        src: designPortfolioImg11,
      },
      {
        id: 3,
        src: designPortfolioImg11,
      },
    ],
    project_title: "Project title",
    project_description:
      "Lorem ipsum dolor sit amet consectetur. Natoque dui non convallis sit. Nunc diam tincidunt orci elit erat iaculis. Nulla at quis ut commodo vel vestibulum suscipit convallis. Vitae non turpis et tempus tristique tellus.",
  },
  {
    id: 12,
    img: [
      {
        id: 1,
        src: designPortfolioImg12,
      },
      {
        id: 2,
        src: designPortfolioImg12,
      },
      {
        id: 3,
        src: designPortfolioImg12,
      },
    ],
    project_title: "Project title",
    project_description:
      "Lorem ipsum dolor sit amet consectetur. Natoque dui non convallis sit. Nunc diam tincidunt orci elit erat iaculis. Nulla at quis ut commodo vel vestibulum suscipit convallis. Vitae non turpis et tempus tristique tellus.",
  },
];

export const serviceSectionData = [
  {
    id: 1,
    service: "Brand Identity Design",
    img: designImage1,
    width: 400,
    description:
      "Establishing a unique and cohesive brand identity is crucial for any business. Our team ensures that your brand stands out with a compelling visual identity that communicates your values and resonates with your audience.",
    buttonText: "Learn More",
  },
  {
    id: 2,
    service: "UI & UX Design",
    img: designImage2,
    width: 400,
    description:
      "Creating user-friendly interfaces and engaging user experiences is our specialty. We focus on intuitive design and seamless interactions to enhance user satisfaction and drive business success.",
    buttonText: "Learn More",
  },
  {
    id: 3,
    service: "Website Development",
    img: developImage1,
    width: 350,
    description:
      "We build responsive and robust websites that are optimized for performance and scalability. Our development process ensures that your website is secure, fast, and tailored to meet your specific business needs.",
    buttonText: "Learn More",
  },
  {
    id: 4,
    service: "Mobile App Development",
    img: developImage2,
    width: 500,
    description:
      "Our team creates high-quality mobile applications for both iOS and Android platforms. We focus on delivering apps that are not only visually appealing but also functional and user-centric.",
    buttonText: "Learn More",
  },
  {
    id: 5,
    service: "E-commerce Store Management",
    img: developImage3,
    width: 336,
    description:
      "Managing an online store requires expertise and precision. We provide comprehensive e-commerce solutions, including inventory management, payment processing, and customer support to help your business thrive.",
    buttonText: "Learn More",
  },
  {
    id: 6,
    service: "Hosting Management",
    img: launchImg1,
    width: 336,
    description:
      "Reliable hosting is essential for the smooth operation of your website. Our hosting management services ensure that your site is always online, fast, and secure, with regular backups and 24/7 support.",
    buttonText: "Learn More",
  },
  {
    id: 7,
    service: "Domain Purchase",
    img: launchImg2,
    width: 336,
    description:
      "We help you find and secure the perfect domain name for your business. Our domain purchase services include registration, renewal, and management to ensure your online presence is well-established.",
    buttonText: "Learn More",
  },
  {
    id: 8,
    service: "Business Email Setup",
    img: launchImg3,
    width: 280,
    description:
      "Professional email communication is key to maintaining a credible business image. We provide business email setup services that include custom email addresses, secure hosting, and spam protection.",
    buttonText: "Learn More",
  },
  {
    id: 9,
    service: "Search Engine Optimization(SEO)",
    img: promoteImg1,
    width: 336,
    description:
      "Improve your website's visibility on search engines with our SEO services. We use the latest techniques and strategies to boost your rankings, drive organic traffic, and increase your online presence.",
    buttonText: "Learn More",
  },
  {
    id: 10,
    service: "Social Media Marketing(SMM)",
    img: promoteImg2,
    width: 336,
    description:
      "Harness the power of social media to grow your brand. Our SMM services include content creation, campaign management, and analytics to engage your audience and achieve your marketing goals.",
    buttonText: "Learn More",
  },
];
