import React, { useRef, useState } from "react";
import footerImage from "../../../assets/footerbg.png";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import SocialIcons from "../../Common/SocialIcons";
import MobileFooter from "./MobileFooter";
import { Link } from "react-router-dom";

const Footer = () => {
  const [visible, setVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef("");
  const handleScroll = (e) => {
    e.preventDefault();
    setShowMenu(false);
    const href = e.currentTarget.href;
    const targetId = href.replace(/.*\#/, "");
    const elem = document.getElementById(targetId);
    elem?.scrollIntoView({
      behavior: "smooth",
    });
    const links = document.querySelectorAll(".nav-link");
    links.forEach((link) => {
      link.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  };

  const handleClick = (e) => {
    if (e.target.contains(ref.current)) {
      setShowMenu(false);
    }
  };
  return (
    <div className="h-[70%] sm:py-[55px] xs:py-[30px] lg:py-[66px] sm1:py-[75px] md1:py-[50px] py-[120px] footerss">
      <h1 className="section-header sm1:w-[530px] mx-auto md:w-[700px] md1:w-[822px] lg:w-[966px] xl:w-[1190px] sm:w-[450px] xs:w-[300px] w-[1440px] xs:text-[18px] sm:text-[26px] sm1:text-[29px] md:text-[36px] md1:text-[38px] lg:text-[40px] text-[50px] text-wrap text-center font-bold leading-[84.75px]">
        Design.Development.Launch.Promote
      </h1>

      <div className="sm1:w-[560px] mx-auto md:w-[630px] md1:w-[822px] lg:w-[966px] xl:w-[1190px] sm:w-[450px] xs:w-[300px] w-[1210px] sm:mt-[30px] xs:mt-[14px] sm1:mt-[40px] mt-[60px] lg:mt-[67px] flex flex-row justify-normal items-start sm1:gap-[38px] md:gap-[52px] lg:gap-[104px] md1:gap-[50px] gap-[195px] xs:pl-0 md:pl-0 pl-3 xs:gap-[23px] sm:gap-[158px]">
        <div className="md:w-[210px]">
          <p className="text-[30px]  md:w-[210px] md1:text-[24px] md:text-[20px] xs:text-[16px] sm:text-[16px] sm1:text-[23px] font-normal text-left text-nowrap md:text-wrap sm1:text-wrap">
            Build your business with us.
          </p>
          <div className="hidden xs:block sm:block mt-4">
            <div className="flex justify-normal gap-4">
              <FaPhone className="text-xl xs:text-[14px] sm:text-[16px] md1:text-[18px]" />
              <div>
                <p className="text-[17px] xs:text-[14px] sm:text-[14px] sm1:text-[16px] font-medium">
                  Phone
                </p>
                <p className="text-[17px] xs:text-[14px] sm:text-[14px] sm1:text-[16px] font-medium text-[#D7D7D7] mt-1">
                  (088) 444-5555
                </p>
              </div>
            </div>
            <div className="flex justify-normal items-center gap-3 my-2 lg:mt-4">
              <IoMail className="text-xl xs:text-[14px] sm:text-[16px] md1:text-[18px]" />
              <div>
                <p className="text-[17px] xs:text-[14px] sm:text-[14px] sm1:text-[16px] font-medium">
                  Email
                </p>
                <p className="text-[17px] xs:text-[14px] sm:text-[14px] sm1:text-[16px] font-medium text-[#D7D7D7] mt-1">
                  support@codellane.com
                </p>
              </div>
            </div>
            <div className="flex justify-normal items-center gap-3">
              <FaLocationDot className="text-xl xs:text-[14px] sm:text-[16px] md1:text-[18px]" />
              <div>
                <p className="text-[17px] xs:text-[14px] sm:text-[14px] sm1:text-[16px] font-medium">
                  Address
                </p>
                <p className="text-[17px] xs:text-[14px] sm:text-[14px] sm1:text-[16px] font-medium text-[#D7D7D7] mt-1">
                  123 road, B green road. Dhaka, Bangladesh
                </p>
              </div>
            </div>
          </div>
          <div className="mt-[32px]">
            <SocialIcons />
          </div>
        </div>

        <div className="sm:hidden xs:hidden block">
          <div className="flex justify-normal gap-4">
            <FaPhone className="text-xl sm:text-[16px] md1:text-[18px]" />
            <div>
              <p className="text-[17px] sm1:text-[16px] font-medium">Phone</p>
              <p className="text-[17px] sm1:text-[16px] font-medium text-[#D7D7D7] mt-1">
                (088) 444-5555
              </p>
            </div>
          </div>
          <div className="flex justify-normal items-center gap-3 my-2 lg:mt-4">
            <IoMail className="text-xl sm:text-[16px] md1:text-[18px]" />
            <div>
              <p className="text-[17px] sm1:text-[16px] font-medium">Email</p>
              <p className="text-[17px] sm1:text-[16px] font-medium text-[#D7D7D7] mt-1">
                support@codellane.com
              </p>
            </div>
          </div>
          <div className="flex justify-normal items-center gap-3">
            <FaLocationDot className="text-xl sm:text-[16px] md1:text-[18px]" />
            <div>
              <p className="text-[17px] sm1:text-[16px] font-medium">Address</p>
              <p className="text-[17px] sm1:text-[16px] font-medium text-[#D7D7D7] mt-1">
                123 road, B green road. Dhaka, Bangladesh
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <p className="text-[18px] sm:text-[15px] xs:text-[14px] sm1:text-[16px] font-bold mb-[20px] text-nowrap">
            <span className="pr-1.5">|</span>
            About us
          </p>
          <Link to="#service" onClick={handleScroll}>
            <p className="text-[18px] sm:text-[15px] xs:text-[14px] sm1:text-[16px] font-normal leading-[24px] pl-3 hover:text-[#396AFD] hover:underline text-nowrap">
              Services
            </p>
          </Link>

          <Link to="#work" onClick={handleScroll}>
            <p className="text-[18px] sm:text-[15px] xs:text-[14px] sm1:text-[16px] font-normal leading-[24px] pl-3 mt-3 hover:text-[#396AFD] hover:underline text-nowrap">
              Our Work
            </p>
          </Link>
          <Link to="#about" onClick={handleScroll}>
            <p className="text-[18px] sm:text-[15px] xs:text-[14px] sm1:text-[16px] font-normal leading-[24px] pl-3 mt-3 hover:text-[#396AFD] hover:underline text-nowrap">
              About Us
            </p>
          </Link>
          <Link to="#contact" onClick={handleScroll}>
            <p className="text-[18px] sm:text-[15px] xs:text-[14px] sm1:text-[16px] font-normal leading-[24px] pl-3 mt-3 hover:text-[#396AFD] hover:underline text-nowrap">
              Contact Us
            </p>
          </Link>
        </div>
      </div>

      {/* mobile device */}
      {/* <MobileFooter /> */}
    </div>
  );
};

export default Footer;
