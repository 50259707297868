import React from "react";
import { MdOutlineArrowForward, MdClose } from "react-icons/md";
import { motion } from "framer-motion";
import SocialIcons from "./SocialIcons";
import { Sidebar } from "primereact/sidebar";

const SidebarNav = ({ visibleRight, setVisibleRight }) => {
  return (
    <Sidebar
      // header={<h2 className="text-[29px] font-bold pl-1 lg:pl-6">Codelane</h2>}
      visible={visibleRight}
      position="right"
      className="bg-black w-full"
      onHide={() => setVisibleRight(false)}>
      <div className="flex justify-between items-center md:max-w-[630px] lg:max-w-[966px] xl:max-w-[1190px] sm:max-w-[450px] xs:max-w-[300px] sm1:w-[530px] md1:max-w-[822px] max-w-[1440px] mx-auto pt-0 px-0">
        <h2 className="text-[29px] font-bold text-white">Codelane</h2>
        <div>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="cursor-pointer"
            onClick={() => setVisibleRight(false)}>
            <MdClose className="text-white text-[24px] lg:text-[32px]" />
          </motion.div>
        </div>
      </div>
      <div className="md:max-w-[630px] lg:max-w-[966px] sm1:w-[530px] xl:max-w-[1190px] sm:max-w-[450px] xs:max-w-[300px] max-w-[1440px] md1:max-w-[822px] mx-auto pt-0 px-0">
        <ul className="space-y-[17px] lg:space-y-[27px] pt-[100px] lg:pt-[61px] xs:pt-[53px] sm:pt-[70px]">
          <li className="md:max-w-[630px] lg:text-[38px] xl:max-w-[1190px] sm:text-[33px] max-w-[1440px] xs:text-[20px] text-[45px] font-medium text-white uppercase text-nowrap">
            Services
          </li>
          <li className="flex justify-normal items-center gap-[14px] lg:gap-[55px]">
            <p className="md:max-w-[630px] lg:text-[38px] xl:max-w-[1190px] sm:text-[33px] max-w-[1440px] xs:text-[20px] text-[45px] font-medium text-white uppercase text-nowrap">
              Our Work
            </p>
            <MdOutlineArrowForward className="text-[#396AFD] text-2xl lg:text-[57px]" />
            <p className="xs:text-[14px] text-[18px] lg:text-[24px] font-medium uppercase text-nowrap">
              Here’s what we offer
            </p>
          </li>
          <li className="md:max-w-[630px] lg:text-[38px] xl:max-w-[1190px] sm:text-[33px] max-w-[1440px] xs:text-[20px] text-[45px] font-medium text-white uppercase text-nowrap">
            About
          </li>
          <li className="md:max-w-[630px] lg:text-[38px] xl:max-w-[1190px] sm:text-[33px] max-w-[1440px] xs:text-[20px] text-[45px] font-medium text-white uppercase text-nowrap">
            Contact
          </li>
        </ul>
      </div>

      <div className="flex justify-start items-start mt-[60px] md:max-w-[630px] lg:max-w-[966px] xl:max-w-[1190px] sm:max-w-[461px] md1:max-w-[822px] sm1:max-w-[530px] xs:max-w-[300px] max-w-[1440px] mx-auto pt-0 px-0 ">
        <SocialIcons />
      </div>
    </Sidebar>
  );
};

export default SidebarNav;
