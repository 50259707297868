import React from "react";
import HeroSection from "./HeroSection/HeroSection";
import ServicesPart from "./ServiceSection/ServicesPart";
import PricingSection from "./PricingSection/PricingSection";
import PortfolioSection from "./PortfolioSection/PortfolioSection";
import About from "./AboutSection/About";
import ContactSection from "./ContactSection/ContactSection";
import MobileService from "./ServiceSection/MobileService";

const Home = () => {
  return (
    <>
      <HeroSection />
      <ServicesPart />
      <MobileService />
      <PricingSection />
      <PortfolioSection />
      <About />
      <ContactSection />
    </>
  );
};

export default Home;
