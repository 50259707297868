import React, { useState } from "react";
import SectionHeader from "../../../Components/Common/SectionHeader";
import DesignTab from "./DesignTab";

const PricingSection = () => {
  const [activeTab, setActiveTab] = useState("Design");

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div
      className="pt-[160px] xl:pt-[160px] lg:pt-[88px] md:max-w-[630px] lg:max-w-[966px] xl:max-w-[1190px] sm:max-w-[450px] xs:max-w-[300px] sm1:w-[530px] md1:max-w-[822px] max-w-[1440px] mx-auto"
      id="pricing">
      <SectionHeader title={"Pricing"} />

      <div className="flex justify-center items-center xs:mt-[30px] sm:mt-[30px] sm1:mt-[50px] md:mt-[50px] md1:mt-[70px] lg:mt-[70px] xl:mt-[70px] mt-[70px] w-[335px] xs:w-[300px] lg:w-[868px] mx-auto rounded-[63px] gap-[40px] xs:gap-[20px] lg:gap-[64px] xl:gap-[64px] md1:gap-[64px]">
        <div
          onClick={() => toggleTab("Design")}
          className={`${
            activeTab === "Design"
              ? "text-[#EEEEEE] font-semibold"
              : "text-[#D7D7D7] font-normal"
          } text-[24px] xs:text-[18px] sm:text-[18px] sm1:text-[18px] md:text-[20px] lg:text-[24px] xl:text-[24px] md1:text-[24px] cursor-pointer z-10`}>
          Design
        </div>
        <div
          onClick={() => toggleTab("Development")}
          className={`${
            activeTab === "Development"
              ? "text-[#EEEEEE] font-semibold"
              : "text-[#D7D7D7] font-normal"
          } text-[24px] xs:text-[18px] sm:text-[18px] sm1:text-[18px] md:text-[20px] lg:text-[24px] xl:text-[24px] md1:text-[24px] cursor-pointer z-10`}>
          Development
        </div>

        <div
          onClick={() => toggleTab("Marketing")}
          className={`${
            activeTab === "Marketing"
              ? "text-[#EEEEEE] font-semibold"
              : "text-[#D7D7D7] font-normal"
          } text-[24px] xs:text-[18px] sm:text-[18px] sm1:text-[18px] md:text-[20px] lg:text-[24px] xl:text-[24px] md1:text-[24px] cursor-pointer z-10`}>
          Marketing
        </div>
      </div>

      {activeTab === "Design" && <DesignTab />}
      {activeTab === "Development" && <DesignTab />}
      {activeTab === "Marketing" && <DesignTab />}
    </div>
  );
};

export default PricingSection;
