import React from "react";
import { FaCheck } from "react-icons/fa";

const PricingCard = ({ priceData }) => {
  console.log(priceData);

  const cardStyle = {
    color: priceData?.color,
    background:
      "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)",
  };

  return (
    <div
      className="w-[225px] sm1:w-[190px] md1:w-[200px] lg:w-[225px] h-[473px] rounded-[11px] border border-[#FFFFFF26] relative"
      style={cardStyle}>
      <h2
        className={`${
          priceData?.package_name == "Pro" ? "text-white" : "text-[#2244FF] "
        } text-[22.68px] font-semibold text-center pt-[29px]`}>
        {priceData?.package_name}
      </h2>
      {priceData?.package_name == "Pro" && (
        <div className="w-[100px] h-[33px] rounded-[8px] text-[#222222] text-[14px] font-medium bg-[#EEEEEE] flex justify-center items-center absolute left-1/2 transform -translate-x-1/2 top-[-16px]">
          Popular
        </div>
      )}

      <p
        className={`text-[29px] text-${[
          priceData?.color,
        ]} font-bold text-center mt-[19px]`}>
        {priceData?.price}
        <span className="text-[13px] font-medium">/month</span>
      </p>

      <div className="space-y-[20px] mt-6 sm:block lg:hidden hidden md1:hidden sm1:hidden xl:hidden xs:block">
        {priceData?.facilities?.map((facility, i) => (
          <div key={i} className="flex justify-center items-center mt-[32px]">
            {/* {facility?.name == "" ? (
              <FaCheck />
            ) : ( */}
            <p className="text-[14px] font-semibold text-white">
              {facility?.name1}
            </p>
            {/* )} */}
          </div>
        ))}
        {/* <p className="sm:text-[14px] xs:text-[12px] text-[14px]  text-center font-semibold text-nowrap">
          All limited links
        </p>
        <p className="sm:text-[14px] xs:text-[12px] text-[14px]  text-center font-semibold text-nowrap">
          Own analytics platform
        </p>
        <p className="sm:text-[14px] xs:text-[12px] text-[14px]  text-center font-semibold text-nowrap">
          Chat support
        </p>
        <p className="sm:text-[14px] xs:text-[12px] text-[14px]  text-center font-semibold text-nowrap">
          Number of users
        </p>
        <p className="sm:text-[14px] xs:text-[12px] text-[14px]  text-center font-semibold text-nowrap">
          Optimize hashtags
        </p> */}
      </div>
      <div className="space-y-[32px] sm:hidden block lg:block md1:block sm1:block xl:block xs:hidden">
        {priceData?.facilities?.map((facility, i) => (
          <div key={i} className="flex justify-center items-center mt-[32px]">
            {facility?.name == "" ? (
              <FaCheck />
            ) : (
              <p className="text-[14px] font-semibold text-white">
                {facility?.name}
              </p>
            )}
          </div>
        ))}
      </div>

      <div className="flex justify-center items-center mt-[27px]">
        <button className="w-[136px] h-[43px] rounded-[4px] bg-[#2244FF] bn2">
          <p className="text-[16px] font-semibold">Choose plan</p>
        </button>
      </div>
    </div>
  );
};

export default PricingCard;
