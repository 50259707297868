import React from "react";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { motion } from "framer-motion";
const Navbar = ({ setVisibleRight }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}>
      <div className="flex justify-between items-center md:w-[630px] md1:w-[822px] lg:w-[966px] xl:w-[1190px] sm:w-[450px] sm1:w-[530px] xs:w-[300px] w-[1440px] mx-auto xs:pt-[35px] pt-[54px] px-0">
        <h2 className="xs:text-[24px] text-[29px] font-bold text-white">
          Codelane
        </h2>
        <div>
          {" "}
          <HiOutlineMenuAlt4
            className="sx:text-[26px] text-[30px] cursor-pointer text-white"
            onClick={() => setVisibleRight(true)}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Navbar;
