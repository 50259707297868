import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";

const MainLayouts = () => {
  return (
    <div className="h-screen">
      <Outlet />
      <Footer />
    </div>
  );
};

export default MainLayouts;
