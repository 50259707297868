import React, { useState } from "react";
import { motion } from "framer-motion";
import designimg1 from "../../../assets/design.png";
import devimg from "../../../assets/development.png";
import lonimg from "../../../assets/launch.png";
import promoimg from "../../../assets/promote.png";
// servicesImage
import designImage1 from "../../../assets/serviceImage/design2.png";
import designImage2 from "../../../assets/serviceImage/design1.png";
import developImage1 from "../../../assets/serviceImage/development1.png";
import developImage2 from "../../../assets/serviceImage/development2.png";
import developImage3 from "../../../assets/serviceImage/development3.png";
import launchImg1 from "../../../assets/serviceImage/launch1.png";
import launchImg2 from "../../../assets/serviceImage/lauch2.png";
import launchImg3 from "../../../assets/serviceImage/launch3.png";
import promoteImg1 from "../../../assets/serviceImage/promote1.png";
import promoteImg2 from "../../../assets/serviceImage/promote2.png";

const MobileService = () => {
  const [activeTab, setActiveTab] = useState("Design");
  const [activeSubTab, setActiveSubTab] = useState("Brand Identity Design");

  const toggleTab = (subTab) => {
    let mainTab = "";
    switch (subTab) {
      case "Brand Identity Design":
      case "UI & UX Design":
        mainTab = "Design";
        break;
      case "Website Development":
      case "Mobile App Development":
      case "E-commerce Store Management":
        mainTab = "Development";
        break;
      case "Hosting Management":
      case "Domain Purchase":
      case "Business Email Setup":
        mainTab = "Launch";
        break;
      case "Search Engine Optimization(SEO)":
      case "Social Media Marketing(SMM)":
        mainTab = "Promote";
        break;
      default:
        mainTab = "Design";
    }
    setActiveTab(mainTab);
    setActiveSubTab(subTab);
  };

  const renderContent = () => {
    switch (activeSubTab) {
      case "Brand Identity Design":
        return (
          <div className="mt-12">
            <img src={designImage1} alt="Brand Identity Design" />
            <p className="text-[16px] font-normal mt-[22px] text-justify">
              Establishing a unique and cohesive brand identity is crucial for
              any business. Our team ensures that your brand stands out with a
              compelling visual identity that communicates your values and
              resonates with your audience.
            </p>
            <button className="text-[18px] font-semibold text-[#FFFFFF] bg-[#2244FF] w-[136px] h-[43px] rounded-[4px] mt-[17px] bn2">
              Learn More
            </button>
          </div>
        );
      case "UI & UX Design":
        return (
          <div className="mt-12">
            <img src={designImage2} alt="UI & UX Design" />
            <p className="text-[16px] font-normal mt-[22px] text-justify">
              Establishing a unique and cohesive brand identity is crucial for
              any business. Our team ensures that your brand stands out with a
              compelling visual identity that communicates your values and
              resonates with your audience.
            </p>
            <button className="text-[18px] font-semibold text-[#FFFFFF] bg-[#2244FF] w-[136px] h-[43px] rounded-[4px] mt-[17px] bn2">
              Learn More
            </button>
          </div>
        );
      case "Website Development":
        return (
          <div className="mt-12">
            <img src={developImage1} alt="Website Development" />
            <p className="text-[16px] font-normal mt-[22px] text-justify">
              We build responsive and robust websites that are optimized for
              performance and scalability. Our development process ensures that
              your website is secure, fast, and tailored to meet your specific
              business needs.
            </p>
            <button className="text-[18px] font-semibold text-[#FFFFFF] bg-[#2244FF] w-[136px] h-[43px] rounded-[4px] mt-[17px] bn2">
              Learn More
            </button>
          </div>
        );
      case "Mobile App Development":
        return (
          <div className="mt-12">
            <img src={developImage2} alt="Mobile App Development" />
            <p className="text-[16px] font-normal mt-[22px] text-justify">
              Our team creates high-quality mobile applications for both iOS and
              Android platforms. We focus on delivering apps that are not only
              visually appealing but also functional and user-centric.
            </p>
            <button className="text-[18px] font-semibold text-[#FFFFFF] bg-[#2244FF] w-[136px] h-[43px] rounded-[4px] mt-[17px] bn2">
              Learn More
            </button>
          </div>
        );
      case "E-commerce Store Management":
        return (
          <div className="mt-12">
            <img src={developImage3} alt="E-commerce Store Management" />
            <p className="text-[16px] font-normal mt-[22px] text-justify">
              Managing an online store requires expertise and precision. We
              provide comprehensive e-commerce solutions, including inventory
              management, payment processing, and customer support to help your
              business thrive.
            </p>
            <button className="text-[18px] font-semibold text-[#FFFFFF] bg-[#2244FF] w-[136px] h-[43px] rounded-[4px] mt-[17px] bn2">
              Learn More
            </button>
          </div>
        );
      case "Hosting Management":
        return (
          <div className="mt-12">
            <img src={launchImg1} alt="Hosting Management" />
            <p className="text-[16px] font-normal mt-[22px] text-justify">
              Managing an online store requires expertise and precision. We
              provide comprehensive e-commerce solutions, including inventory
              management, payment processing, and customer support to help your
              business thrive.
            </p>
            <button className="text-[18px] font-semibold text-[#FFFFFF] bg-[#2244FF] w-[136px] h-[43px] rounded-[4px] mt-[17px] bn2">
              Learn More
            </button>
          </div>
        );
      case "Domain Purchase":
        return (
          <div className="mt-12">
            <img src={launchImg2} alt="Domain Purchase" />
            <p className="text-[16px] font-normal mt-[22px] text-justify">
              Managing an online store requires expertise and precision. We
              provide comprehensive e-commerce solutions, including inventory
              management, payment processing, and customer support to help your
              business thrive.
            </p>
            <button className="text-[18px] font-semibold text-[#FFFFFF] bg-[#2244FF] w-[136px] h-[43px] rounded-[4px] mt-[17px] bn2">
              Learn More
            </button>
          </div>
        );
      case "Business Email Setup":
        return (
          <div className="mt-12">
            <img src={launchImg3} alt="Business Email Setup" />
            <p className="text-[16px] font-normal mt-[22px] text-justify">
              Managing an online store requires expertise and precision. We
              provide comprehensive e-commerce solutions, including inventory
              management, payment processing, and customer support to help your
              business thrive.
            </p>
            <button className="text-[18px] font-semibold text-[#FFFFFF] bg-[#2244FF] w-[136px] h-[43px] rounded-[4px] mt-[17px] bn2">
              Learn More
            </button>
          </div>
        );
      case "Search Engine Optimization(SEO)":
        return (
          <div className="mt-12">
            <img src={promoteImg1} alt="SEO" />
            <p className="text-[16px] font-normal mt-[22px] text-justify">
              Managing an online store requires expertise and precision. We
              provide comprehensive e-commerce solutions, including inventory
              management, payment processing, and customer support to help your
              business thrive.
            </p>
            <button className="text-[18px] font-semibold text-[#FFFFFF] bg-[#2244FF] w-[136px] h-[43px] rounded-[4px] mt-[17px] bn2">
              Learn More
            </button>
          </div>
        );
      case "Social Media Marketing(SMM)":
        return (
          <div className="mt-12">
            <img src={promoteImg2} alt="SMM" />
            <p className="text-[16px] font-normal mt-[22px] text-justify">
              Managing an online store requires expertise and precision. We
              provide comprehensive e-commerce solutions, including inventory
              management, payment processing, and customer support to help your
              business thrive.
            </p>
            <button className="text-[18px] font-semibold text-[#FFFFFF] bg-[#2244FF] w-[136px] h-[43px] rounded-[4px] mt-[17px] bn2">
              Learn More
            </button>
          </div>
        );
      default:
        return <div>Content not available</div>;
    }
  };

  return (
    <>
      <div className="hidden xs:block sm:block sm1:block xl:hidden lg:hidden md1:hidden md:hidden h-[889px] py-[70px] rounded-[10px] servicess relative">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.1 }}
          className="xs:hidden sm:hidden xl:w-[792px] lg:w-[792px] md1:w-[692px] md:w-[592px] sm1:w-[400px] sm:w-[300px] w-[792px] h-[58.1px] rounded-[10px] mx-auto mt-[32px] border border-[#FFFFFF26] flex"
          style={{
            background:
              "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)",
          }}>
          <div
            className={`${
              activeTab === "Design"
                ? "text-[#EEEEEE] border-0 border-r border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } sm1:w-[100px] md:w-[148px] md1:w-[171px] w-[198px] text-[17px] font-semibold text-[#EEEEEE] h-full flex justify-center items-center rounded-l-[9px] cursor-pointer`}
            style={{
              background:
                activeTab === "Design"
                  ? "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Brand Identity Design")}>
            Design
          </div>
          <div
            className={`${
              activeTab === "Development"
                ? "text-[#EEEEEE] border-0 border-r border-l border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } sm1:w-[150px] md:w-[148px] md1:w-[171px] w-[198px] text-[17px] font-semibold text-[#EEEEEE] h-full flex justify-center items-center cursor-pointer`}
            style={{
              background:
                activeTab === "Development"
                  ? "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Website Development")}>
            Development
          </div>
          <div
            className={`${
              activeTab === "Launch"
                ? "text-[#EEEEEE] border-0 border-r border-l border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } sm1:w-[96px] md:w-[148px] md1:w-[171px] w-[198px] text-[17px] font-semibold text-[#EEEEEE] h-full flex justify-center items-center cursor-pointer`}
            style={{
              background:
                activeTab === "Launch"
                  ? "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Hosting Management")}>
            Launch
          </div>
          <div
            className={`${
              activeTab === "Promote"
                ? "text-[#EEEEEE] border-0 border-r border-l border-[#FFFFFF26] rounded-r-[9px]"
                : "text-[#b5b5b5]"
            } sm1:w-[109px] md:w-[148px] md1:w-[171px] w-[198px] text-[17px] font-semibold text-[#EEEEEE] h-full flex justify-center items-center cursor-pointer`}
            style={{
              background:
                activeTab === "Promote"
                  ? "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Search Engine Optimization(SEO)")}>
            Promote
          </div>
        </motion.div>
        {/* mobile */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.1 }}
          className="xl:hidden lg:hidden md1:hidden hidden md:hidden sm1:hidden xl:w-[792px] lg:w-[792px] md1:w-[692px] md:w-[592px] sm1:w-[400px] sm:w-[46px] xs:w-[46px] w-[792px] sm:h-[184px] xs:h-[184px] h-[58.1px] rounded-[10px] mr-auto absolute top-32 left-0 mt-[32px] border border-[#FFFFFF26] xs:block sm:block"
          style={{
            background:
              "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)",
          }}>
          <div
            className={`${
              activeTab === "Design"
                ? "text-[#EEEEEE] border-0 border-t border-b border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } sm:w-[46px] xs:w-[46px] sm1:w-[100px] md:w-[148px] md1:w-[171px] w-[198px] text-[17px] font-semibold text-[#EEEEEE] xs:h-[46px] sm:h-[46px] h-full flex justify-center items-center rounded-t-[9px] cursor-pointer`}
            style={{
              background:
                activeTab === "Design"
                  ? "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Design")}>
            <img src={designimg1} alt="" />
          </div>
          <div
            className={`${
              activeTab === "Development"
                ? "text-[#EEEEEE] border-0 border-t border-b border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } sm:w-[46px] xs:w-[46px] sm1:w-[150px] md:w-[148px] md1:w-[171px] w-[198px] text-[17px] font-semibold text-[#EEEEEE] xs:h-[46px] sm:h-[46px]  h-full flex justify-center items-center cursor-pointer`}
            style={{
              background:
                activeTab === "Development"
                  ? "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Development")}>
            <img src={devimg} alt="" />
          </div>
          <div
            className={`${
              activeTab === "Launch"
                ? "text-[#EEEEEE] border-0 border-t border-b border-[#FFFFFF26]"
                : "text-[#b5b5b5]"
            } sm:w-[46px] xs:w-[46px] sm1:w-[96px] md:w-[148px] md1:w-[171px] w-[198px] text-[17px] font-semibold text-[#EEEEEE] xs:h-[46px] sm:h-[46px] h-full flex justify-center items-center cursor-pointer`}
            style={{
              background:
                activeTab === "Launch"
                  ? "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Launch")}>
            <img src={lonimg} alt="" />
          </div>
          <div
            className={`${
              activeTab === "Promote"
                ? "text-[#EEEEEE] border-0 border-t border-b border-[#FFFFFF26] rounded-b-[13px]"
                : "text-[#b5b5b5]"
            } sm:w-[46px] xs:w-[46px] sm1:w-[109px] md:w-[148px] md1:w-[171px] w-[198px] text-[17px] font-semibold text-[#EEEEEE] xs:h-[46px] sm:h-[46px] h-full flex justify-center items-center cursor-pointer`}
            style={{
              background:
                activeTab === "Promote"
                  ? "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)"
                  : "none",
            }}
            onClick={() => toggleTab("Promote")}>
            <img src={promoimg} alt="" />
          </div>
        </motion.div>
        <div className="mt-[40px] sm1:w-[530px] mx-auto md:w-[630px] md1:w-[700px] lg:w-[822px] xl:w-[946px] sm:w-[330px] xs:w-[300px] w-[1340px] lg:hidden xl:hidden md1:hidden md:hidden block">
          <div className="flex justify-center items-center gap-5 overflow-x-scroll sm1:w-[530px] mx-auto md:w-[630px] md1:w-[700px] lg:w-[822px] xl:w-[946px] sm:w-[380px] xs:w-[300px] w-[1340px] lg:hidden xl:hidden md1:hidden md:hidden">
            {[
              "Brand Identity Design",
              "UI & UX Design",
              "Website Development",
              "Mobile App Development",
              "E-commerce Store Management",
              "Hosting Management",
              "Domain Purchase",
              "Business Email Setup",
              "Search Engine Optimization(SEO)",
              "Social Media Marketing(SMM)",
            ].map((service) => (
              <p
                key={service}
                className={`text-[18px] font-semibold text-nowrap ${
                  activeSubTab === service ? "text-[#2244FF]" : ""
                }`}
                onClick={() => toggleTab(service)}>
                {service}
              </p>
            ))}
          </div>
          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default MobileService;
